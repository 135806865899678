import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DataService from "../services/data.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Add this import

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => {
        document.title = "Reset Password";
    }, []);

    const forgotPass = (e) => {
        e.preventDefault();
        const data = { email };
        setLoading(true);
        DataService.forgotPassword(data).then(
            (res) => {
                setLoading(false);
                toast.success(res?.data?.msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setTimeout(() => {
                    navigate("/login");
                }, 1500);
            },
            (error) => {
                const resMessage = error?.response?.data?.msg;
                setLoading(false);
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        );
    };

    return (
        <>
            <div className="row align-items-stretch justify-content-between w-100">
                <div className="col-sm-6" style={{ background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="pop">
                        <Link to={"/"}>
                            <img src="../assets/img/adminlogo.png" className="main_logo mb-4" alt="" />
                        </Link>
                        <h5 className="mb-4">Please Enter your Email to get Reset Password Link</h5>
                        <form onSubmit={forgotPass}>
                            <div className="row">
                                <div className="col-md-12">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-12 mt-4">
                                    <div className="form-btn">
                                        <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-sm-6">
                    <img src="../assets/img/pana.png" style={{ objectFit: 'cover' }} className="w-100 h-100" alt="reset-password" />
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
